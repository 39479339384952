import React from "react"

import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const SplittersPage = () => (
  <LayoutNew>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Coax Splitters – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <div className="mx-auto prose prose-xl text-center">
      <br></br>
      <br></br>
      <h1>Example Coax Splitters</h1>
      <p>
        Try to follow the coax line back from your modem to see if you have any
        splitters in your home.{" "}
      </p>
      <p>
        They come in all shapes and sizes however the below are the most popular
        ones.
      </p>
      <StaticImage
        src="../content/splitters.png"
        alt="Various splitters where one coax cable goes in, but two come out."
      />
    </div>
  </LayoutNew>
)

export default SplittersPage
